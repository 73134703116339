import { GridRowParams } from '@mui/x-data-grid';
import {
  ExtendedAppearanceStatus,
  FormikProps,
  Offer,
  ScheduleTimeFrame,
} from 'common/contracts';
import { DATE_TIME_FORMAT_UTC } from 'constants/constants';
import {
  EAppearanceStatus,
  EPendingAppearanceStatus,
  EScheduleType
} from 'constants/enums';
import dayjs, { Dayjs } from 'dayjs';
import { EStatusLabel } from 'design-system/StatusLabel/types';
import { FormikValues } from 'formik';

export const STATUS_PRIORITY = {
  [EAppearanceStatus.RUNNING]: 3,
  [EAppearanceStatus.UPCOMING]: 2,
  [EAppearanceStatus.ENDED]: 1
};

// ---- Status Utilities ----
export const statusUtils = {
  determineStatus(startTime: Date, endTime: Date): EAppearanceStatus {
    const currentDate = new Date();
    if (currentDate >= startTime && currentDate <= endTime) {
      return EAppearanceStatus.RUNNING;
    } else if (currentDate < startTime) {
      return EAppearanceStatus.UPCOMING;
    } else {
      return EAppearanceStatus.ENDED;
    }
  },

  getStatusLabel(status: ExtendedAppearanceStatus): EStatusLabel {
    const statusLabelMap: Partial<
      Record<ExtendedAppearanceStatus, EStatusLabel>
    > = {
      [EAppearanceStatus.RUNNING]: EStatusLabel.ACTIVE,
      [EAppearanceStatus.UPCOMING]: EStatusLabel.PENDING,
      [EPendingAppearanceStatus.PENDING_SAVE]: EStatusLabel.CANCELED
    };
    return statusLabelMap[status] ?? EStatusLabel.FAILED;
  }
};

// ---- Time Utilities ----
export const timeUtils = {
  parseCronToHour(cronString: string): string {
    const [minute, hour] = cronString.split(' ');
    return `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}`;
  },

  generateCronString(hourMinute: string): string {
    const [hour, minute] = hourMinute.split(':').map(Number);
    return `${minute} ${hour} * * *`;
  },

  isOverlapping(
    newStartTime: Dayjs,
    newEndTime: Dayjs,
    values: FormikValues
  ): boolean {
    return values?.schedule?.timeFrames.some((timeFrame: ScheduleTimeFrame) => {
      const existingStart = new Date(timeFrame.startTime).getTime();
      const existingEnd = new Date(timeFrame.endTime).getTime();
      return (
        (newStartTime.valueOf() >= existingStart &&
          newStartTime.valueOf() <= existingEnd) ||
        (newEndTime.valueOf() >= existingStart &&
          newEndTime.valueOf() <= existingEnd) ||
        (newStartTime.valueOf() <= existingStart &&
          newEndTime.valueOf() >= existingEnd)
      );
    });
  }
};

// ---- Appearance Utilities ----
export const appearanceUtils = {
  getRowClassName: (params: GridRowParams<Offer>): string => {
    const { schedule } = params.row;
    if (schedule?.permanent) return '';

    const timeFrames = schedule?.timeFrames || [];
    const { status } = appearanceUtils.getStrongestStatusWithTime(timeFrames);
    return status === EAppearanceStatus.ENDED ? 'row-ended' : '';
  },

  getStrongestStatusWithTime(timeFrames: ScheduleTimeFrame[] = []): {
    status: EAppearanceStatus | EScheduleType.PERMANENT;
    appearanceTime: string;
  } {
    if (!timeFrames.length) {
      return {
        status: EScheduleType.PERMANENT,
        appearanceTime: '-'
      };
    }

    const initialAccumulator = {
      status: EAppearanceStatus.ENDED,
      appearanceTime: '-'
    };

    return timeFrames.reduce((acc, timeFrame) => {
      const currentStatus = statusUtils.determineStatus(
        new Date(timeFrame.startTime),
        new Date(timeFrame.endTime)
      );

      if (STATUS_PRIORITY[currentStatus] > STATUS_PRIORITY[acc.status]) {
        return {
          status: currentStatus,
          appearanceTime: dayjs
            .utc(timeFrame.startTime)
            .format(DATE_TIME_FORMAT_UTC)
        };
      }
      return acc;
    }, initialAccumulator);
  },

  sortByStatusOrder(
    params1: { id: string | number },
    params2: { id: string | number },
    appearancesTableData: any
  ): number {
    const rowA = appearancesTableData?.find(
      (row: any) => row.id === params1.id
    );
    const rowB = appearancesTableData?.find(
      (row: any) => row.id === params2.id
    );

    if (!rowA || !rowB) return 0;

    const statusA = rowA?.startTime && rowA?.endTime
      ? statusUtils.determineStatus(
          new Date(rowA?.startTime),
          new Date(rowA?.endTime)
        )
      : appearanceUtils.getStrongestStatusWithTime(
          rowA?.schedule?.timeFrames || []
        ).status;

    const statusB = rowB.startTime && rowB?.endTime
      ? statusUtils.determineStatus(
          new Date(rowB.startTime),
          new Date(rowB.endTime)
        )
      : appearanceUtils.getStrongestStatusWithTime(
          rowB?.schedule?.timeFrames || []
        ).status;

    // Handle EScheduleType.PERMANENT explicitly
    const priorityA =
      STATUS_PRIORITY[statusA as EAppearanceStatus] ||
      (statusA === EScheduleType.PERMANENT ? 0 : -1);
    const priorityB =
      STATUS_PRIORITY[statusB as EAppearanceStatus] ||
      (statusB === EScheduleType.PERMANENT ? 0 : -1);

    return priorityA - priorityB;
  }
};

// ---- Validation Utilities ----
export const validationUtils = {
  getErrorMessage(
    startDate: Dayjs,
    endDate: Dayjs,
    diffMinutes: number,
    values: FormikProps,
    editRangeDatesValues: boolean | null
  ): string | null {
    const startHour = startDate.hour();
    const endHour = endDate.hour();
    const isInvalidTimeRange =
      startHour > endHour ||
      (startHour === endHour && startDate.minute() >= endDate.minute());

    if (!startDate || !endDate) return null;

    if (startDate.isSame(endDate, 'day')) {
      if (isInvalidTimeRange) {
        return 'Start time cannot be after or at the same time as the end time.';
      }
      if (diffMinutes <= 0) {
        return 'An appearance must have a duration greater than 0.';
      }
    } else if (diffMinutes > 100 * 24 * 60) {
      return "You can't select a time range that exceeds 100 days.";
    }

    if (
      timeUtils.isOverlapping(startDate, endDate, values) &&
      !editRangeDatesValues
    ) {
      return 'Selected time range overlaps with an existing range.';
    }

    return null;
  }
};
