import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useSelector } from 'react-redux';

import {
  AddToHomeScreenButton,
  Background,
  createGradientOrSolid,
  overrideCssVars
} from '@appcharge/shared-ui';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import useImages from 'api/useImages';
import useUsers from 'api/useUsers';
import {
  EAssetType,
  EBundlesInternalViewModel,
  EFeatureFlag
} from 'constants/enums';
import { useFormikContext } from 'formik';
import { UploadsTooltips, useUploads } from 'hooks/useUpload';
import { AuthSliceState } from 'store/store.types';
import { permissionsUtil } from 'utils/permissionsUtil';

import AcCard from 'components/AcCard/AcCard';
import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';
import CustomizedSwitch from 'components/SwitchButton/SwitchButton';

import { ThemeFormValues } from '../theme.types';
import { getFonts } from '../utils';

import 'style/forms.scss';

export const GeneralSettings: React.FC = () => {
  const { values, handleBlur, handleChange, setFieldValue, errors, touched } =
    useFormikContext<ThemeFormValues>();
  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );
  const pictures = useImages(currentPublisherId).getImages;
  const addPictureInputRef = useRef<HTMLInputElement>(null);
  const [currentUploadingField, setCurrentUploadingField] = useState('');

  const { uploadImage } = useUploads();

  const [isProductMadness, setIsProductMadness] = useState<boolean>(false);
  useEffect(() => {
    const bundleViewModel = values?.general?.bundlesInternalViewModel;
    setIsProductMadness(
      [
        EBundlesInternalViewModel.JACKPOT_SLOTS,
        EBundlesInternalViewModel.BIG_FISH_CASINO
      ].includes(bundleViewModel)
    );
  }, [values.general.bundlesInternalViewModel]);

  const { fetchFeatureFlags } = useUsers({ publisherId: currentPublisherId });

  const showAddToHomeScreen: boolean = useMemo(
    () =>
      fetchFeatureFlags.data?.featureFlags?.[
        EFeatureFlag.DASHBOARD_ADD_TO_HOME_SCREEN
      ],
    [fetchFeatureFlags?.data]
  );

  const cssMappingOverride = {
    '--button-text-color': values.general.buttonTextColor,
    '--button-color': createGradientOrSolid(values.general.buttonColor),
    '--bundle-border-color': createGradientOrSolid(
      values.storeScreen.bundleBorderColor
    )
  };

  overrideCssVars(cssMappingOverride, document);

  const showNewPrivacySection: boolean = useMemo(
    () =>
      fetchFeatureFlags.data?.featureFlags?.[
        EFeatureFlag.DASHBOARD_NEW_PRIVACY_RICH_TEXT
      ],
    [fetchFeatureFlags?.data]
  );

  return (
    <Grid container className="formContent">
      <Grid item xs={5}>
        {!pictures.isLoading && (
          <Stack>
            <AcCard stackContainer={false} title="Store Essentials">
              <Grid container rowSpacing={2} columnSpacing={1.5}>
                <Grid item xs={12}>
                  <AcInput
                    header="Store Tab Title"
                    name="general.tabName"
                    value={values.general.tabName}
                    tooltip="This will be the title of your store tab."
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.general?.tabName &&
                      Boolean(errors.general?.tabName)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <input
                    style={{ display: 'none' }}
                    type="file"
                    onChange={(e) =>
                      uploadImage(
                        e,
                        addPictureInputRef.current as HTMLInputElement,
                        pictures,
                        setFieldValue,
                        setCurrentUploadingField,
                        currentUploadingField
                      )
                    }
                    ref={addPictureInputRef}
                    accept="image/*"
                  />
                  <AcSelect
                    header="Logo"
                    name="general.logo"
                    value={values.general.logo}
                    uploadConfig={{
                      onUploadSuccess: async (uploadData: any) => {
                        await pictures.refetch();
                        setFieldValue('general.logo', uploadData!.data.url);
                      },
                      uploadType: EAssetType.LOGO,
                      uploadMessage: UploadsTooltips[EAssetType.LOGO]
                    }}
                    items={[
                      {
                        content: 'Default Appcharge logo',
                        key: 'https://media.appcharge.com/defaults/logo.svg',
                        value: 'https://media.appcharge.com/defaults/logo.svg',
                        renderFunction: () => {
                          return (
                            <Stack direction="row" alignItems="center" gap={1}>
                              <img
                                src="https://media.appcharge.com/defaults/logo.svg"
                                alt="Default Appcharge logo"
                                style={{
                                  maxWidth: 26
                                }}
                              />
                              <Typography>Default Appcharge logo</Typography>
                            </Stack>
                          );
                        }
                      },
                      ...pictures.data
                        .filter((p: any) => p.type === EAssetType.LOGO)
                        .map((picture: any) => {
                          return {
                            content: picture.name,
                            key: picture.url,
                            value: picture.url,
                            renderFunction: () => {
                              return (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  gap={1}
                                >
                                  <img
                                    src={picture.url}
                                    alt={picture.name}
                                    style={{
                                      width: 26,
                                      maxHeight: 30
                                    }}
                                  />
                                  <Typography>{picture.name}</Typography>
                                </Stack>
                              );
                            }
                          };
                        })
                    ]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.general?.logo && Boolean(errors.general?.logo)
                    }
                    tooltip="This will be the logo of your store."
                  />
                </Grid>
                <Grid item xs={12}>
                  <AcSelect
                    header="Favicon"
                    name="general.favicon"
                    value={values.general.favicon}
                    uploadConfig={{
                      onUploadSuccess: async (uploadData: any) => {
                        await pictures.refetch();
                        setFieldValue('general.favicon', uploadData!.data.url);
                      },
                      uploadType: EAssetType.FAVICON,
                      uploadMessage: UploadsTooltips[EAssetType.FAVICON]
                    }}
                    items={[
                      {
                        content: 'Default Appcharge favicon',
                        key: 'https://media.appcharge.com/defaults/logo.svg',
                        value: 'https://media.appcharge.com/defaults/logo.svg',
                        renderFunction: () => {
                          return (
                            <Stack direction="row" alignItems="center" gap={1}>
                              <img
                                src="https://media.appcharge.com/defaults/logo.svg"
                                alt="Default Appcharge favicon"
                                style={{
                                  maxWidth: 30
                                }}
                              />
                              <Typography>Default Appcharge logo</Typography>
                            </Stack>
                          );
                        }
                      },
                      ...pictures.data
                        .filter((p: any) => p.type === EAssetType.FAVICON)
                        .map((picture: any) => {
                          return {
                            content: picture.name,
                            key: picture.url,
                            value: picture.url,
                            renderFunction: () => {
                              return (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  gap={1}
                                >
                                  <img
                                    src={picture.url}
                                    alt={picture.name}
                                    style={{
                                      width: 30,
                                      maxHeight: 30
                                    }}
                                  />
                                  <Typography>{picture.name}</Typography>
                                </Stack>
                              );
                            }
                          };
                        })
                    ]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.general?.favicon &&
                      Boolean(errors.general?.favicon)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <AcSelect
                    header="Background Image (Mobile)"
                    name="general.backgroundImageMobile"
                    value={values.general.backgroundImageMobile}
                    uploadConfig={{
                      onUploadSuccess: async (uploadData: any) => {
                        await pictures.refetch();
                        setFieldValue(
                          'general.backgroundImageMobile',
                          uploadData!.data.url
                        );
                      },
                      uploadType: EAssetType.BG_MOBILE,
                      uploadMessage: UploadsTooltips[EAssetType.BG_MOBILE]
                    }}
                    items={[
                      {
                        content: 'Default Appcharge background',
                        key: 'https://media.appcharge.com/defaults/background.png',
                        value:
                          'https://media.appcharge.com/defaults/background.png',
                        renderFunction: () => {
                          return (
                            <Stack direction="row" alignItems="center" gap={1}>
                              <img
                                src="https://media.appcharge.com/defaults/background.png"
                                alt="Default Appcharge background"
                                style={{
                                  width: 30,
                                  maxHeight: 30
                                }}
                              />
                              <Typography>
                                Default Appcharge background
                              </Typography>
                            </Stack>
                          );
                        }
                      },
                      ...pictures.data
                        .filter((p: any) => p.type === EAssetType.BG_MOBILE)
                        .map((picture: any) => {
                          return {
                            content: picture.name,
                            key: picture.url,
                            value: picture.url,
                            renderFunction: () => {
                              return (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  gap={1}
                                >
                                  <img
                                    src={picture.url}
                                    alt={picture.name}
                                    style={{
                                      width: 30,
                                      maxHeight: 30
                                    }}
                                  />
                                  <Typography>{picture.name}</Typography>
                                </Stack>
                              );
                            }
                          };
                        })
                    ]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.general?.backgroundImageMobile &&
                      Boolean(errors.general?.backgroundImageMobile)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <AcSelect
                    header="Background Image (Desktop)"
                    name="general.backgroundImageDesktop"
                    value={values.general.backgroundImageDesktop}
                    uploadConfig={{
                      onUploadSuccess: async (uploadData: any) => {
                        await pictures.refetch();
                        setFieldValue(
                          'general.backgroundImageDesktop',
                          uploadData!.data.url
                        );
                      },
                      uploadType: EAssetType.BG_DESKTOP,
                      uploadMessage: UploadsTooltips[EAssetType.BG_DESKTOP]
                    }}
                    items={[
                      {
                        content: 'Default Appcharge background',
                        key: 'https://media.appcharge.com/defaults/background.png',
                        value:
                          'https://media.appcharge.com/defaults/background.png',
                        renderFunction: () => {
                          return (
                            <Stack direction="row" alignItems="center" gap={1}>
                              <img
                                src="https://media.appcharge.com/defaults/background.png"
                                alt="Default Appcharge background"
                                style={{
                                  width: 30,
                                  maxHeight: 30
                                }}
                              />
                              <Typography>
                                Default Appcharge background
                              </Typography>
                            </Stack>
                          );
                        }
                      },
                      ...pictures.data
                        .filter((p: any) => p.type === EAssetType.BG_DESKTOP)
                        .map((picture: any) => {
                          return {
                            content: picture.name,
                            key: picture.url,
                            value: picture.url,
                            renderFunction: () => {
                              return (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  gap={1}
                                >
                                  <img
                                    src={picture.url}
                                    alt={picture.name}
                                    style={{
                                      width: 30,
                                      maxHeight: 30
                                    }}
                                  />
                                  <Typography>{picture.name}</Typography>
                                </Stack>
                              );
                            }
                          };
                        })
                    ]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.general?.backgroundImageDesktop &&
                      Boolean(errors.general?.backgroundImageDesktop)
                    }
                  />
                </Grid>
                {isProductMadness && (
                  <Grid item xs={12}>
                    <AcSelect
                      header="Store Sale"
                      name="general.storeSaleImage"
                      value={values?.general?.storeSaleImage}
                      uploadConfig={{
                        onUploadSuccess: async (uploadData: any) => {
                          await pictures.refetch();
                          setFieldValue(
                            'general.storeSaleImage',
                            uploadData!.data.url
                          );
                        },
                        uploadType: EAssetType.GENERAL,
                        uploadMessage: UploadsTooltips[EAssetType.GENERAL]
                      }}
                      items={[
                        ...pictures.data
                          .filter((p: any) => p.type === EAssetType.GENERAL)
                          .map((picture: any) => {
                            return {
                              content: picture.name,
                              key: picture.url,
                              value: picture.url,
                              renderFunction: () => {
                                return (
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    gap={1}
                                  >
                                    <img
                                      src={picture.url}
                                      alt={picture.name}
                                      style={{
                                        width: 30,
                                        maxHeight: 30
                                      }}
                                    />
                                    <Typography>{picture.name}</Typography>
                                  </Stack>
                                );
                              }
                            };
                          })
                      ]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.general?.storeSaleImage &&
                        Boolean(errors.general?.storeSaleImage)
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </AcCard>
            <Divider />
            <AcCard stackContainer={false} title="Font">
              <Grid container rowSpacing={2} columnSpacing={1.5}>
                <Grid item xs={12}>
                  <AcSelect
                    name="general.font"
                    value={values.general.font}
                    items={getFonts()}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.general?.font && Boolean(errors.general?.font)
                    }
                  />
                </Grid>
              </Grid>
            </AcCard>
            <Divider />
            <AcCard
              stackContainer={false}
              title="Footer"
              description="These settings will apply  throughout your store"
            >
              <Grid container rowSpacing={2} columnSpacing={1.5}>
                <Grid item xs={12}>
                  <AcSelect
                    header="Footer Image (Mobile)"
                    name="general.footerImageMobile"
                    value={values.general.footerImageMobile}
                    uploadConfig={{
                      onUploadSuccess: async (uploadData: any) => {
                        await pictures.refetch();
                        setFieldValue(
                          'general.footerImageMobile',
                          uploadData!.data.url
                        );
                      },
                      uploadType: EAssetType.LOGO,
                      uploadMessage: UploadsTooltips[EAssetType.LOGO]
                    }}
                    items={[
                      {
                        content: 'No image',
                        key: 'no-image',
                        value: '',
                        renderFunction: () => (
                          <Stack direction="row" alignItems="center" gap={1}>
                            <span
                              style={{
                                width: 30,
                                height: 30,
                                display: 'inline-block',
                                background: '#eee',
                                textAlign: 'center',
                                lineHeight: '30px'
                              }}
                            ></span>
                            <Typography>No Image</Typography>
                          </Stack>
                        )
                      },
                      {
                        content: 'Default Appcharge logo',
                        key: 'https://media.appcharge.com/defaults/logo.svg',
                        value: 'https://media.appcharge.com/defaults/logo.svg',
                        renderFunction: () => {
                          return (
                            <Stack direction="row" alignItems="center" gap={1}>
                              <img
                                src="https://media.appcharge.com/defaults/logo.svg"
                                alt="Default Appcharge logo"
                                style={{
                                  maxWidth: 26
                                }}
                              />
                              <Typography>Default Appcharge logo</Typography>
                            </Stack>
                          );
                        }
                      },
                      ...pictures.data
                        .filter((p: any) => p.type === EAssetType.LOGO)
                        .map((picture: any) => {
                          return {
                            content: picture.name,
                            key: picture.url,
                            value: picture.url,
                            renderFunction: () => {
                              return (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  gap={1}
                                >
                                  <img
                                    src={picture.url}
                                    alt={picture.name}
                                    style={{
                                      width: 30,
                                      maxHeight: 30
                                    }}
                                  />
                                  <Typography>{picture.name}</Typography>
                                </Stack>
                              );
                            }
                          };
                        })
                    ]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.general?.footerImageMobile &&
                      Boolean(errors.general?.footerImageMobile)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <AcSelect
                    header="Footer Image (Desktop)"
                    name="general.footerImageDesktop"
                    value={values.general.footerImageDesktop}
                    uploadConfig={{
                      onUploadSuccess: async (uploadData: any) => {
                        await pictures.refetch();
                        setFieldValue(
                          'general.footerImageDesktop',
                          uploadData!.data.url
                        );
                      },
                      uploadType: EAssetType.LOGO,
                      uploadMessage: UploadsTooltips[EAssetType.LOGO]
                    }}
                    items={[
                      {
                        content: 'No image',
                        key: 'no-image',
                        value: '',
                        renderFunction: () => (
                          <Stack direction="row" alignItems="center" gap={1}>
                            <span
                              style={{
                                width: 30,
                                height: 30,
                                display: 'inline-block',
                                background: '#eee',
                                textAlign: 'center',
                                lineHeight: '30px'
                              }}
                            ></span>
                            <Typography>No Image</Typography>
                          </Stack>
                        )
                      },
                      {
                        content: 'Default Appcharge logo',
                        key: 'https://media.appcharge.com/defaults/logo.svg',
                        value: 'https://media.appcharge.com/defaults/logo.svg',
                        renderFunction: () => {
                          return (
                            <Stack direction="row" alignItems="center" gap={1}>
                              <img
                                src="https://media.appcharge.com/defaults/logo.svg"
                                alt="Default Appcharge logo"
                                style={{
                                  maxWidth: 26
                                }}
                              />
                              <Typography>Default Appcharge logo</Typography>
                            </Stack>
                          );
                        }
                      },
                      ...pictures.data
                        .filter((p: any) => p.type === EAssetType.LOGO)
                        .map((picture: any) => {
                          return {
                            content: picture.name,
                            key: picture.url,
                            value: picture.url,
                            renderFunction: () => {
                              return (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  gap={1}
                                >
                                  <img
                                    src={picture.url}
                                    alt={picture.name}
                                    style={{
                                      width: 30,
                                      maxHeight: 30
                                    }}
                                  />
                                  <Typography>{picture.name}</Typography>
                                </Stack>
                              );
                            }
                          };
                        })
                    ]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.general?.footerImageDesktop &&
                      Boolean(errors.general?.footerImageDesktop)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <AcInput
                    headerSize="14"
                    header="Footer Appstore Link"
                    name="general.footerAppstoreLink"
                    value={values.general.footerAppstoreLink}
                    placeholder="https://www.apple.com/app-store/"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.general?.footerAppstoreLink &&
                      Boolean(errors.general?.footerAppstoreLink)
                    }
                    helperText={
                      touched.general?.footerAppstoreLink
                        ? errors.general?.footerAppstoreLink?.toString()
                        : ''
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <AcInput
                    headerSize="14"
                    header="Footer GooglePlay Link"
                    name="general.footerGooglePlayLink"
                    value={values.general.footerGooglePlayLink}
                    placeholder="https://play.google.com/"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.general?.footerGooglePlayLink &&
                      Boolean(errors.general?.footerGooglePlayLink)
                    }
                    helperText={
                      touched.general?.footerGooglePlayLink
                        ? errors.general?.footerGooglePlayLink?.toString()
                        : ''
                    }
                  />
                </Grid>
              </Grid>
            </AcCard>
            {permissionsUtil.isSuperAdmin() && !showNewPrivacySection && (
              <>
                <Divider />
                <AcCard stackContainer={false} title="Privacy Policy and T&C">
                  <Grid container rowSpacing={2} columnSpacing={1.5}>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Typography mr={4} fontSize={14} color="#717188">
                          Enable to open Privacy Policy and T&C in a new tab.
                          Disable to open in a modal window.
                        </Typography>
                        <CustomizedSwitch
                          texts={[]}
                          status={values.login.openLinksInNewTab}
                          functions={[
                            () =>
                              setFieldValue('login.openLinksInNewTab', false),
                            () => setFieldValue('login.openLinksInNewTab', true)
                          ]}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </AcCard>
              </>
            )}
            <Divider />
            <AcCard stackContainer={false} title="Lack of Special Offers">
              <Grid container rowSpacing={2} columnSpacing={1.5}>
                <Grid item xs={12}>
                  <AcInput
                    headerSize="14"
                    header="Lack of Special Offers Title Text"
                    name="storeScreen.noOffersTitleText"
                    value={values.storeScreen.noOffersTitleText}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.storeScreen?.noOffersTitleText &&
                      Boolean(errors.storeScreen?.noOffersTitleText)
                    }
                    helperText={
                      touched.storeScreen?.noOffersTitleText
                        ? errors.storeScreen?.noOffersTitleText?.toString()
                        : ''
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <AcInput
                    headerSize="14"
                    header="Lack of Special Offers Message Text"
                    name="storeScreen.noOffersMessageText"
                    value={values.storeScreen.noOffersMessageText}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.storeScreen?.noOffersMessageText &&
                      Boolean(errors.storeScreen?.noOffersMessageText)
                    }
                    helperText={
                      touched.storeScreen?.noOffersMessageText
                        ? errors.storeScreen?.noOffersMessageText?.toString()
                        : ''
                    }
                  />
                </Grid>
              </Grid>
            </AcCard>
          </Stack>
        )}
      </Grid>
      <Grid item xs={7} className="iphone-mock-wrapper">
        <div
          className="store-preview-wrapper iphone-mock"
          style={{ overflow: 'scroll' }}
        >
          {showAddToHomeScreen && (
            <AddToHomeScreenButton
              borderColor={
                values.storeScreen?.addToHomeScreen?.borderColor.colorOne
              }
              backgroundColor={
                values.storeScreen.addToHomeScreen.backgroundColor?.colorOne
                  ? values.storeScreen.addToHomeScreen.backgroundColor
                  : {
                      colorOne: '#522AD8',
                      colorTwo: '#7946FA'
                    }
              }
              buttonImage={values.storeScreen.addToHomeScreen?.buttonImage}
              onClick={() => ''}
              isMockPreview
            />
          )}

          <Background
            backgroundImageMobile={values.general.backgroundImageMobile}
            backgroundImageDesktop={values.general.backgroundImageMobile}
            width="100%"
            height="100%"
            position="absolute"
          />
          <img
            style={{ position: 'relative' }}
            alt="logo"
            className={`storeLogo ${values.general.logoSize}`}
            src={values.general.logo}
          />
        </div>
      </Grid>
    </Grid>
  );
};
